/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Poppins", -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", "Roboto", sans-serif;
  --ion-font-size: "50px";
  --ion-background-color: #d2d6de;
  --ion-card-background: #fff;
  --ion-item-background: transparent;
  --ion-label-color: #53505f;
  --ion-icon-color: #c72465;
  --ion-menu-selected-color-rgb: 199, 36, 101;

  --ion-toolbar-background: linear-gradient(to right, #c72465, #ea837c);
  --ion-toolbar-color: #fff;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #cc122b;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf253b;
  --ion-color-danger-tint: #f0304a;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* LEGACY */

ion-title.title-large {
  font-size: 25px;
}

ion-card {
  overflow: visible;
}

ion-label {
  color: #000;
  font-weight: 600;
}

ion-text {
  color: #000;
}

ion-textarea {
  margin-top: 5px;
  width: 100%;
  color: #555555 !important;
  background-color: #ffffff !important;
  background-image: none;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  line-height: 1.5;
  padding: 1px 6px;
}

ion-datetime,
ion-select,
ion-input,
input.mask {
  margin-top: 5px !important;
  height: 42px !important;
  width: 100% !important;
  padding: 6px 12px !important;
  color: #555555 !important;
  background-color: #ffffff !important;
  background-image: none !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  line-height: 2 !important;
}

input[disabled],
.select-disabled {
  background: hsl(0, 0%, 95%) !important;
  color: #000 !important;
  opacity: 0.6 !important;
}

.card-pendente {
  background-color: #e0ffff;
}

.card-pendente ion-text,
.card-pendente ion-label,
.card-pendente ion-card-title {
  color: blue !important;
}

.card-agendado {
  background-color: #bddef4;
}

.card-suspenso {
  background-color: #f2dede;
}

.card-realizado {
  background-color: #afa;
}

.card-andamento {
  background-color: #ff9;
}

.card-cancelado {
  background-color: orange;
}

.card-semrh {
  background-color: pink;
}

.card-s_maquina {
  background-color: #d8c3e2;
}

ion-card {
  overflow: visible;
}

ion-text {
  color: #000;
}

ion-label {
  color: #000;
  font-weight: 600;
}

.toast-message {
  width: 100;
  overflow: hidden;
  color: red !important;
}

ion-title.title-large {
  font-size: 25px;
}

.card-danger {
  background: rgba(255, 0, 0, 0.5);
}

.urgent {
  color: red;
}